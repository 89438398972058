import React from "react";
import { Hidden } from "@material-ui/core";

interface ResponsiveComponentProps {
  sp: React.ReactNode;
  pc: React.ReactNode;
}

const ResponsiveComponent: React.FC<ResponsiveComponentProps> = ({
  sp,
  pc,
}) => {
  return (
    <>
      <Hidden smDown implementation="css">
        {pc}
      </Hidden>
      <Hidden mdUp implementation="css">
        {sp}
      </Hidden>
    </>
  );
};

export default ResponsiveComponent;
