import React, { useEffect } from "react";

import SearchBar from "material-ui-search-bar";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";

export default function Search() {
  const location = useLocation();
  const [keyword, setKeyword] = React.useState("");

  const handleRequestSearch = () => {
    const encodedKeyword = keyword ? encodeURI(keyword) : "";

    if (encodedKeyword && encodedKeyword.trim() !== "") {
      navigate(`/search-result?keyword=${encodedKeyword}`);
    }
  };

  useEffect(() => {
    const url = new URL(location.href ?? "");
    const _keyword = new URLSearchParams(url.search ?? "").get("keyword") ?? "";
    setKeyword(_keyword);
  }, [location]);
  return (
    <>
      <div style={{ backgroundColor: "rgb(247, 249, 249)" }}>
        <SearchBar
          style={{
            height: "35px",
            boxShadow: "none",
            border: "solid #858585 1px",
          }}
          placeholder="キーワードで探す"
          value={keyword || ""}
          onChange={(newValue) => setKeyword(newValue)}
          onRequestSearch={handleRequestSearch}
        />
      </div>
    </>
  );
}
