import { createStyles, makeStyles } from "@material-ui/core";

export const drawerMenus = [
  { name: "ボート", path: "/categories/powerboat" },
  { name: "ヨット", path: "/categories/SAILBOAT" },
  { name: "クルージング", path: "/categories/cruising" },
  { name: "ヨットレース", path: "/categories/sailboat-racing" },
  { name: "ボートフィッシング", path: "/categories/boat-fishing" },
  { name: "マリーナ", path: "/categories/marina" },
  { name: "カヌー", path: "/categories/canoe" },
  { name: "グッズ", path: "/categories/goods" },
  { name: "トピックス", path: "/categories/topics" },
  { name: "マガジン", path: "/categories/magazine" },
];

export const sites = [
  { name: "舵社（出版物紹介）", path: "https://www.kazi.co.jp/" },
  { name: "マリンショップKAZIシープラザ", path: "https://www.seaplaza.jp/" },
];

export const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      backgroundColor: "rgb(247, 249, 249)",
      boxShadow: "none",
    },
    drawerPaper: {
      backgroundColor: "black",
      width: 250,
    },
    menu: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "-43px",
    },
    brandContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: "12px",
    },
    item: {
      color: "white",
    },
    title1: {
      paddingLeft: "8px",
      color: "white",
    },
    title2: {
      paddingLeft: "8px",
      color: "white",
      marginTop: "30px",
    },
    divider: {
      backgroundColor: "white",
    },
    closeIcon: {
      color: "white",
      display: "flex",
      justifyContent: "flex-end",
      marginRight: theme.spacing(1),
    },
  })
);
