import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/mainPage.js";
import classNames from "classnames";

const useStyles = makeStyles(styles);

type Props = {
  style?: any;
  children: any;
};

export default function Container(props: Props) {
  const { children, style } = props;
  const classes = useStyles();

  return (
    <>
      <div
        style={style}
        className={classNames(classes.main, classes.mainRaised)}
      >
        <div className={classes.container}>{children}</div>
      </div>
    </>
  );
}

Container.defaultProps = {
  style: {},
};
