import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import { Link } from "gatsby";
import { ReactComponent as Logo } from "assets/img/logo.svg";
import { ReactComponent as LogoXs } from "assets/img/logoXs.svg";
import Tab from "components/molecures/Tab";
import Search from "components/atoms/Search";
import {
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { ReactComponent as Menu } from "assets/img/menu.svg";
import ResponsiveComponent from "components/atoms/ResponsiveComponent";
import { drawerMenus, sites, useStyles } from "./component";

// --------------------------------------------------

function Header(props: any) {
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { color, changeColorOnScroll } = props;

  // 起動
  React.useEffect(() => {
    if (changeColorOnScroll.height === 0) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color as keyof typeof classes]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(
          classes[changeColorOnScroll.color as keyof typeof classes]
        );
    }
  }, [changeColorOnScroll, classes, color]);

  return (
    <AppBar className={classes.appBar}>
      <Container style={{ boxShadow: "none" }}>
        <div>
          <ResponsiveComponent
            sp={
              <>
                <div className={classes.brandContainer}>
                  <a href="/">
                    <LogoXs />
                  </a>
                </div>
                <div className={classes.menu}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                  >
                    <Menu />
                  </IconButton>
                </div>
                <div style={{ padding: "12px" }}>
                  <Search />
                </div>
              </>
            }
            pc={
              <>
                <div
                  className={classes.brandContainer}
                  style={{ paddingRight: "12px" }}
                >
                  <a href="/">
                    <Logo />
                  </a>
                </div>
                <div className={classes.menu} style={{ paddingBottom: "12px" }}>
                  <Search />
                </div>
              </>
            }
          />
        </div>
      </Container>
      <Drawer
        variant="temporary"
        anchor={"right"}
        open={mobileOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={handleDrawerToggle}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleDrawerToggle}
          aria-label="close"
          className={classes.closeIcon}
        >
          <Typography variant="subtitle1" color="inherit">
            ×閉じる
          </Typography>
        </IconButton>

        <List>
          <Typography variant="subtitle1" classes={{ root: classes.title1 }}>
            カテゴリ
          </Typography>
          {drawerMenus.map((category, index) => (
            <React.Fragment key={index}>
              <ListItem
                button
                component={Link}
                to={category.path}
                classes={{ root: classes.item }}
              >
                <ListItemText primary={category.name} />
              </ListItem>
              <Divider classes={{ root: classes.divider }} />
            </React.Fragment>
          ))}
          <Typography variant="subtitle1" classes={{ root: classes.title2 }}>
            関連サイト
          </Typography>
          {sites.map((category, index) => (
            <React.Fragment key={index}>
              <ListItem
                button
                component={Link}
                to={category.path}
                classes={{ root: classes.item }}
              >
                <ListItemText primary={category.name} />
              </ListItem>
              {index < sites.length - 1 && (
                <Divider classes={{ root: classes.divider }} />
              )}
            </React.Fragment>
          ))}
        </List>
      </Drawer>
      <div>
        <Tab />
      </div>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  categories: PropTypes.array,
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]).isRequired,
  }),
};

export default React.memo(Header);
