import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { navigate } from "gatsby";
import theme from "theme";

// --------------------------------------------------

const useStyles = makeStyles({
  tabContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    backgroundColor: `${theme.palette.primary.main}`,
  },
  root: {
    backgroundColor: `${theme.palette.primary.main}`,
    "& .MuiTabs-indicator": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
    "& .MuiTab-root": {
      minWidth: "110px",
    },
  },
  tab: {
    height: "42px",
    "&:hover, &.Mui-selected": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
  },
  tabMobile: {
    height: "38px",
    "&:hover, &.Mui-selected": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
  },
  label: {
    color: `${theme.palette.common.white}`,
    fontSize: "14px",
    fontWeight: "normal",
  },
});

const NAV = [
  {
    name: "ボート",
    slug: "powerboat",
  },
  {
    name: "ヨット",
    slug: "sailboat",
  },
  {
    name: "クルージング",
    slug: "cruising",
  },
  {
    name: "ヨットレース",
    slug: "sailboat-racing",
  },
  {
    name: "ボートフィッシング",
    slug: "boat-fishing",
  },
  {
    name: "マリーナ",
    slug: "marina",
  },
  {
    name: "カヌー",
    slug: "canoe",
  },
  {
    name: "トピックス",
    slug: "topics",
  },
  {
    name: "グッズ",
    slug: "goods",
  },
  {
    name: "マガジン",
    slug: "magazine",
  },
];

// --------------------------------------------------

export default function CenteredTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState("");

  const handleChange = (slug: string) => {
    navigate(`/categories/${slug}`);
  };
  React.useEffect(() => {
    const path = window.location.pathname.split("/")[2];
    setValue(path);
  }, []);

  return (
    <div className={classes.tabContainer}>
      <Tabs
        value={value}
        centered
        variant="scrollable"
        onChange={(event, newValue) => handleChange(newValue)}
        textColor="secondary"
        scrollButtons="on"
        className={classes.root}
        TabScrollButtonProps={{
          style: {
            color: `${theme.palette.common.white}`,
          },
        }}
      >
        {NAV.map((tab: any, key) => (
          <Tab
            key={key}
            className={classes.tabMobile}
            label={<div className={classes.label}>{tab.name}</div>}
            value={tab.slug}
          />
        ))}
      </Tabs>
    </div>
  );
}
